import React from "react";
import Swal from "sweetalert2";

export default function SignUp() {
  return (
    <section className="deals-sign-up mt-4 mb-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="card border-0 col-10">
            <div className="card-body">
              <h2 className="text-center font-weight-bold mb-4">
                Sign up to receive Newsletter directly to your email
              </h2>
              <form
                action="https://christianblue.com/wp/wp-json/contact-form-7/v1/contact-forms/9874/feedback"
                method="post"
                className="row justify-content-center"
                onSubmit={(e) => {
                  e.preventDefault();
                  const formElement = e.target,
                    { action, method } = formElement,
                    body = new FormData(formElement);

                  fetch(action, {
                    method,
                    body,
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      // Determine if the submission is not valid
                      Swal.fire({
                        text: "Thanks for joining Christian Blue",
                        type: "success",
                      });
                      e.target.reset();
                    })
                    .catch((error) => {
                      // Handle the case when there's a problem with the request
                    });
                }}
              >
                <div className="col-md-6 col-lg-4 d-inline-block mb-2">
                  <label
                    htmlFor="first-name"
                    className="text-normal font-weight-light d-block"
                  >
                    First Name <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    className="w-100 py-2 pl-2 form-control"
                    id="first-name"
                    placeholder="First Name"
                    required
                  />
                </div>
                 <div className="col-md-6 col-lg-4 d-inline-block mb-2">
                  <label
                    htmlFor="last-name"
                    className="text-normal font-weight-light d-block"
                  >
                   Last Name<span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    className="w-100 py-2 pl-2 form-control"
                    id="last-name"
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div className="col-md-12 col-lg-8 mb-2">
                  <label
                    htmlFor="your-email"
                    className="text-normal font-weight-light d-block"
                  >
                    Email Id<span className="text-red">*</span>
                  </label>
                  <input
                    type="email"
                    name="your-email"
                    className="w-100 py-2 pl-2 form-control"
                    id="your-email"
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div className="col-lg-6  text-center mb-2">
                  <button type="submit" className="btn btn-md-block ">
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
