/* eslint array-callback-return: 0 */
import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
// import Empty from "../images/empty.jpg"
import SignUp from "./signup";
import Ads from "./ads";
import Loader from "../images/loader.gif";
import settings from "./settings.json";
import { GlobalStateContext } from "../store/GlobalContextProvider";
const axios = require("axios");

export default function Deals() {
  const state = useContext(GlobalStateContext);
  const [deals, setDeals] = useState([]);
  const [categories, setCategories] = useState([]);

  const [dealsMar, setDealsMar] = useState([]);

  const [coupon, setCoupon] = useState();
  const [display, setDisplay] = useState(true);
  const [show, setShow] = useState(false);
  const [selectValMarket, setSelectValMarket] = useState("-1");
  const [selectValCat, setSelectValCat] = useState("-1");

  const [marketList, setMarketList] = useState([]);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  useEffect(() => {
    axios({
      url: settings.apiURL,
      method: "post",
      data: {
        query: `{
          markets{
            id
            name
            market_cities{
              city
              state_code
            }
          }
        }`,
      },
    }).then((res) => {
      res.data.data.markets.forEach((element) => {
        var marketObj = {
          id: element.id,
          name: element.name,
        };

        setMarketList((oldArray) => [...oldArray, marketObj]);
      });
    });
  }, []);

  const getDeals = () => {
    axios({
      url: settings.apiURL,
      method: "post",
      data: {
        query: `{
          deals(category: "", market: "") {
            id
            name
            market
            icon
            coupon
            base64
            expiry
            description
            category_id
            address
            user_id
          }
          markets{
            id
            name
            market_cities{
              city
              state_code
            }
          }
        }`,
      },
    })
      .then((res) => {
        
        let locationString =
          localStorage.getItem("new_city_name") &&
          localStorage.getItem("new_city_name") !== "null"
            ? localStorage.getItem("new_city_name")
            : localStorage.getItem("city_name");

        var city = locationString?.split(",")[0];
        var stateCode = locationString?.split(", ")[1];

        const markets = res.data.data.markets;
        const checkIfCItyPresentsFinal = (cities) => {
          return (
            cities.findIndex(
              (c) => c.city === city && c.state_code === stateCode
            ) >= 0
          );
        };
        const marketIndex = markets.findIndex((m) => {
          return checkIfCItyPresentsFinal(m.market_cities);
        });

        if (marketIndex !== -1) {
          setSelectValMarket(markets[marketIndex].id);
        } else {
          setSelectValMarket("-1");
        }

        const allDeals = res.data.data.deals;

        setDeals(allDeals);
        //SET DEALS CATEGORY AND MARKET
        var catArr = [];
        var marArr = [];
        allDeals.map((item) => {
          catArr.push(...item.category_id.split(", "));
          marArr.push(item.market);
        });

        //REMOVE ALL DUPLICATES FROM catArr
        // catArr.filter( (value, index, self) => return self.indexOf(value) === index );
        // THE ABOVE LINE WILL BASICALLY RETURN ALL VALUES FROM THEIR OWN INDEX ONLY AND SKIP ALL FOLLOWING DUPLICATES

        setDealsMar(marArr);

        setDisplay(false);
        return "success";
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getCategories = () => {
    axios({
      url: settings.apiURL,
      method: "post",
      data: {
        query: `{
        
          category(for:"deals"){
            id
            icon
            slug
            name
          }
        }
           `,
      },
    })
      .then((res) => {
        setCategories(res.data.data.category);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      ignore = true;
      getCategories();
      getDeals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.locationToggle]);

  const filterDeals = () => {
    var filteredDeals = [...deals];

    if (selectValCat !== "-1") {
      filteredDeals = filteredDeals.filter((d) =>
        d.category_id.split(", ").includes(selectValCat.replaceAll(",", ""))
      );
    }
    if (selectValMarket !== "-1") {
      filteredDeals = filteredDeals.filter(
        (d) => d.market.toString() === selectValMarket
      );
    }

    return filteredDeals;
  };

  const download = (coupon) => {
    let link = document.createElement("a");
    link.href = coupon;
    link.setAttribute("download", "coupon.png");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      <main>
        <section className="deals-banner">
          <h1
            style={{ paddingTop: `70px` }}
            className="text-center font-weight-bold mb-0"
          >
            Christian Blue Deals
          </h1>
        </section>

        <Ads zoneid={79} />
        <section className="deals-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card border-0">
                  <div className="card-header bg-white border-0">
                    <div className="row">
                      <div className="col-md-5 col-lg-4 col-xl-3 mb-2">
                        <h2 className="mb-0">All Coupons</h2>
                      </div>
                      {!display && (
                        <>
                          <div className="col-md-3 col-lg-3 mb-2">
                            <div className="input-group">
                              <select
                                onChange={(e) => {
                                  setSelectValMarket(e.target.value);
                                }}
                                value={selectValMarket}
                                className="form-control py-2 dropdown-z"
                                id=""
                                name="market"
                              >
                                <option value="-1">All Markets</option>
                                {marketList.map((market) => {
                                  if (dealsMar.includes(market.id)) {
                                    return (
                                      <option
                                        value={market.id}
                                        key={`market${market.id}`}
                                      >
                                        {market.name}
                                      </option>
                                    );
                                  }
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3 col-lg-3 mb-2">
                            <div className="input-group">
                              <select
                                onChange={(e) => {
                                  setSelectValCat(e.target.value);
                                }}
                                value={selectValCat}
                                className="form-control dropdown-z"
                                id=""
                                name="category"
                              >
                                <option value="-1">All Categories</option>
                                {categories?.map(
                                  (item) => {
                                    // console.log(categories);
                                    // if (
                                    //   dealsCat.includes(
                                    //     item.name.replace(",", "")
                                    //   )
                                    // ) {
                                    return (
                                      <option
                                        value={item.name}
                                        key={`category${item.id}`}
                                      >
                                        {item.name}
                                      </option>
                                    );
                                  }
                                  //}
                                )}
                              </select>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {display && (
                        <p className="loader-wrapper">
                          <img
                            alt="Loader"
                            height="100px"
                            width="100px"
                            style={{ margin: 50 }}
                            src={Loader}
                          />
                        </p>
                      )}
                      {filterDeals().length
                        ? filterDeals().map((item) => {
                            return (
                              <div
                                className="col-md-6 col-lg-4 mb-4"
                                key={`deals${item.id}`}
                              >
                                <div className="card border-0 p-2">
                                  <img
                                    height="250px"
                                    width="200px"
                                    className="card-img-top"
                                    src={item.coupon}
                                    style={{ objectFit: `contain` }}
                                    alt="N/A"
                                  />
                                  <div className="card-body pl-4 pt-5">
                                    <p className="h3">{item.name}</p>
                                    <p className="h3 discount-line mb-4">
                                      {item.description}
                                    </p>
                                    <p className="h5 mb-2">{item.user_id}</p>
                                    <p className="h5 m-0 font-weight-light">
                                      {item.address}
                                    </p>
                                  </div>
                                  <div className="card-footer pl-4 bg-white border-0 pb-5">
                                    <div className="btn-group" role="group">
                                      <button
                                        onClick={() => {
                                          handleShow();
                                          setCoupon(item.coupon);
                                        }}
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        <span className="font-weight-light">
                                          View
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : !display && (
                            <div className="col-md-12 alert alert-danger text-center mt-3">
                              Sorry, we did not find any coupons.
                            </div>
                          )}

                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="dwld-cpn__modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Download Coupon</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {
                            <img
                              height="250px"
                              width="200px"
                              className="card-img-top"
                              src={coupon}
                              style={{ objectFit: `contain` }}
                              id="couponImg"
                              alt="N/A"
                            />
                          }
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <button
                            onClick={() => {
                              download(coupon);
                            }}
                            type="button"
                            className="btn btn-light"
                          >
                            <span className="font-weight-light">Download</span>
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <SignUp />
      </main>
    </div>
  );
}
