import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Deals from "../components/deals";
import SeO from "../components/SEO";

export default function deals() {
  return (
    <div>
      <SeO
        title={`Deals`}
        defer={true}
        description={`This is list of all deals which are live now on christian blue`}
      />
      <Header />
      <Deals />
      <Footer />
    </div>
  );
}
